(function() {
    angular.module('customerVault', [
        'ngSanitize', 'ui.router', 'ngResource', 'ui.bootstrap', 'angular-loading-bar', 'ui-notification',
        'authApp', 'cp.ngConfirm', 'markdown', 'ui.select', 'ngclipboard', 'oc.lazyLoad', 'angularSlideables', 'ngAnimate', 'ngRoute',
        'customerVault.home', 'customerVault.site', 'customerVault.license', 'customerVault.insight', 'customerVault.layout',
        'customerVault.ie', 'customerVault.cloud', 'customerVault.subscription', 'customerVault.wurfljs', 'customerVault.impersonate', 'customerVault.filex',
        'googlechart', 'customerVault.filters', 'customerVault.capabilities', 'customerVault.zendesk',
        'dndLists', 'nk.touchspin', 'angularMoment', 'rzModule', 'btorfs.multiselect', 'vcRecaptcha',
        'bsLoadingOverlay', 'timer', 'ngMaterial', 'ngMessages', 'customerVault.quwurfl'
    ])
    .config(function(NotificationProvider) {
        NotificationProvider.setOptions({
            delay: 5000,
            startTop: 20,
            startRight: 10,
            verticalSpacing: 20,
            horizontalSpacing: 20,
            positionX: 'center',
            positionY: 'top',
            maxCount: 0,
            templateUrl: 'view/components.notification'
        });
    });
})();
