(function() {
    'use strict';

    angular.module('customerVault')
        .run(runBlock);

    runBlock.$inject = ['$rootScope', '$state', '$window', 'authenticate', 'bsLoadingOverlayService', 'loaderService', 'loginService', 'resolveService', 'sessionService'];
    function runBlock($rootScope, $state, $window, authenticate, bsLoadingOverlayService, loaderService, loginService, resolveService, sessionService) {
        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
            authenticate.createIntended(toState, toParams);
            if (toState.name !== 'otherwise') {
                sessionService.runSessionCheck(event, toState, toParams);
            } else {
                $rootScope.$broadcast('stateChangeOccurred', {name: toState.name});
            }
        });

        $rootScope.$on('$stateChangeSuccess', function() {
            $rootScope.$broadcast('checkPaymentState');
        })

        $rootScope.$on('loading', function(event, args) {
            if (!loginService.impersonate.mode && !_.isNull(localStorage.getItem('impersonate'))) {
                var impersonateUser = JSON.parse(localStorage.getItem('impersonate'));
                loginService.impersonate.mode = true;
                loginService.impersonate.user = impersonateUser;
            }

            var states = args.name.split(".");
            var parent = args.name.split(".")[0];

            if (parent == 'account') {
                if (
                    (!loginService.impersonate.mode && loginService.authInfo.user.id == args.params.id) ||
                    (loginService.impersonate.mode && loginService.impersonate.user.id == args.params.id)
                ) {
                    sessionService.swap();
                    loaderService.close();
                    loaderService.show = false;
                    $state.go('myaccount');
                    return;
                }
                parent = 'myaccount';
            }

            if (states.length > 2) {
                resolveService[parent]({name: states[0]+"."+states[1], params: args.params, main: true}).then(function(response) {
                    if (response) {
                        args.main = false;
                        resolveService[parent](args).then(function(response) {
                            loaderService.close();
                            $window.scrollTo(0, 0);
                            $state.go(args.name, args.params);
                        });
                    } else {
                        runError(args);
                    }
                });
            } else {
                var main = states.length == 1 ? true : false;
                var name = main ? states[0] : states[0]+"."+states[1];
                resolveService[parent]({name: name, params: args.params, main: main}).then(function(response) {
                    if (response) {
                        loaderService.close();
                        $window.scrollTo(0, 0);
                        $state.go(args.name, args.params);
                    } else {
                        runError(args);
                    }
                });
            }
        });

        function runError(args) {
            loaderService.close();
            var error = resolveService.error;
            $state.go(args.name, args.params);
        }

        bsLoadingOverlayService.setGlobalConfig({
            templateUrl: 'view/components.overlay',
            activeClass: 'zindex-master'
        });
    }
})();
